@import "./variables.scss";

/* Spacing */
.spacer {
  background: transparent;
  width: 100%;
  height: 20px;
}

.dividerShape {
  margin: $dividerShapeMargin;
  width: $dividerShapeWidth;
  height: 2px;
  background-image: linear-gradient(to right, transparent, #95d0fb,transparent);
}

.dividerShape2 {
  margin: -5px 0 0 10px;
  width: 100%;
  height: 65px;
  transform:skew(-10deg);
  background-image:  linear-gradient(to right, transparent, #0b80d4);
}

/* Margin */
.marginCenter {
  margin: 0 auto;
}

/* Cards */  
.MuiCard-root {
  border-top: 5px $primary solid;
}

/* Typography */
.pageTitle {
  margin-top: 25px;
  padding-top: 25px;
  color: $primary;
  font-size: 40px;
  font-weight: bold;
  font-family: $titleFont;
  font-variant-caps: all-small-caps;
}

// Search Radio Button Text
.css-ahj2mt-MuiTypography-root {
  font-size: 14px;
  color: #00ACD4;
}

/* Button */
.containedButton {
  background: #33abfe;
  width: 155px;
  height: 55px;
  border-radius: 7px;
  font-weight: 800;
  font-size: large;
  color: #fff;
  margin-right: 10%;
}

/* Bubble Box */ 

.validationBubble {
  font-family: arial, sans-serif;
  font-size: 1.1em;
  color: #fff;
  background: #ed3333;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  max-width: 320px;
  font-size: 18px;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.validationBubble::after {
  content: "";
  border: 20px solid transparent;
  position: absolute;
  color: #fff;
}
 

.bottom.validationBubble::after {
  
  border-top-color: #ed3333;
  border-bottom: 0;
  bottom: -20px; left: 50%;
  margin-left: -20px;
}

/* Animations */

.fade {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  -webkit-animation: fadeinout 4s linear forwards;
  animation: fadeinout 4s linear forwards;
  opacity: 0;
  font-family: $titleFont;
  font-size: 25px;
  color: #ed3939;
}

@-webkit-keyframes fadeinout {
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  50% {
    opacity: 1;
  }
}

.fadePanel {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.spin {
  -webkit-animation: spin 7s linear infinite;
  -moz-animation: spin 7s linear infinite;
  animation: spin 7s linear infinite;
}

.spin-back {
  -webkit-animation: spin-back 7s linear infinite;
  -moz-animation: spin-back 7s linear infinite;
  animation: spin-back 7s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin-back {
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin-back {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin-back {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

/* Pulse */

.pulse {
  animation: infinite 3s textPulse;
}

@keyframes textPulse {
  0%,
  100% {
    color: #197ec6;
  }

  25% {
    color: #479fde;
  }

  50% {
    color: #84c6f5;
  }

  75% {
    color: #86c6f4;
  }
}

@keyframes circleFlasherPulse {
  0%,
  100% {
    background-color: #016bb5;
  }

  25% {
    background-color: #1583d1;
  }

  50% {
    background-color: #349ce6;
  }

  75% {
    background-color: #49a8ed;
  }
}

/* Search */
.search-icon::before {
  content: url(data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTUuNTU4IDUxNS41NTgiIHZpZXdCb3g9IjAgMCA1MTUuNTU4IDUxNS41NTgiIGZpbGw9IiNmZmYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTM3OC4zNDQgMzMyLjc4YzI1LjM3LTM0LjY0NSA0MC41NDUtNzcuMiA0MC41NDUtMTIzLjMzMyAwLTExNS40ODQtOTMuOTYxLTIwOS40NDUtMjA5LjQ0NS0yMDkuNDQ1cy0yMDkuNDQ0IDkzLjk2MS0yMDkuNDQ0IDIwOS40NDUgOTMuOTYxIDIwOS40NDUgMjA5LjQ0NSAyMDkuNDQ1YzQ2LjEzMyAwIDg4LjY5Mi0xNS4xNzcgMTIzLjMzNy00MC41NDdsMTM3LjIxMiAxMzcuMjEyIDQ1LjU2NC00NS41NjRjMC0uMDAxLTEzNy4yMTQtMTM3LjIxMy0xMzcuMjE0LTEzNy4yMTN6bS0xNjguODk5IDIxLjY2N2MtNzkuOTU4IDAtMTQ1LTY1LjA0Mi0xNDUtMTQ1czY1LjA0Mi0xNDUgMTQ1LTE0NSAxNDUgNjUuMDQyIDE0NSAxNDUtNjUuMDQzIDE0NS0xNDUgMTQ1eiIvPjwvc3ZnPg==);
  width: 100%;
  height: auto;
}

.searchContainer {
  height: 100px;
}

.main {
  display: flex;
  margin: 25px auto;
  justify-content: center;
  align-items: center;
}
.search-box {
  display: inline-flex;
  // padding: 2.5px;
  border: none;
  outline: none;
  border-radius: 30px;
  box-shadow: 1px 3.5px 4.5px 0px rgba(0, 0, 0, 0.4);
  background: #fff;
}
.search-box:focus-within {
  /* IE11 WILL IGNORE BECAUSE IT IS NOT SUPPORTED */
  border-color: dodgerblue;
}

.dropDown {
  display: none;
}


.searchContainer .dropDown,
.search-box .dropDown:focus,
.search-box .dropDown:valid {
  display: block;
  border-left: 2px #dddddd solid;
  padding-left: 35px;
}

.searchContainer .input {
  padding-left: 35px;
}

.search-box .input {
  outline: none;
  border: none;
  width: 550px;
  background-color: transparent;
  font-family: $titleFont;
  color: #2f2f2f;
  font-size: 25px;
  padding: 0;
  transition: width 0.4s cubic-bezier(0.18, 0.89, 0.32, 1.33);
}

.search-box .input::placeholder {
  color: $primary;
}

.searchContainer .input,
.search-box .input:focus,
.search-box .input:valid,
.search-box .input:after {
  margin-left: 5px;
  width: 650px;
}

.search-box button {
  outline: none;
  border: none;
  cursor: pointer;
  animation: circleFlasherPulse 2s ease infinite alternate;
  margin-top: 8px;
  margin-right: 8px;
  padding: 11px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-appearance: none;
}

.MuiToggleButtonGroup-root {
  width: 150px;
  height: 10px;
  margin-top: -7px;
}

.MuiToggleButton-root {
  height: 10px;
  width: 200px;
  font-size: 10px;
}

/* Table */
.MuiTableBody-root {
  text-align: left;
}

.MuiTableBody-root .download:hover {
  background: #ebf5fd;
}

.MuiTableHead-root .MuiTableCell-root {
  font-style: bold;
  background: #016bb5;
  color: #fff;
}

/* Capitalize */
.capitalize::first-letter {
  text-transform: capitalize;
}

/* Ellipsis */
.truncate {
  color: $primary;
  white-space: nowrap;
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Typography */
.MuiTypography-h6 {
  color: #525252;
}

.file {
  color: $primary;
}

/* Animations */
.lineDrawing {
  width: 75px;
  height: 3px;
  position: absolute;
  top: 358px;
  left: 675px;
  transform: translate(-50%, -50%);
  display: block;
}

.lineDrawing::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $primary;
  animation: lineAnimate 5s linear infinite;
}

@keyframes lineAnimate {
  0% {
    left: 0;
  }

  50% {
    left: 100%;
  }

  0% {
    left: 0;
  }
}

/* Floating */
.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 55px;
  margin-top: 5px;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

/* Typing */
.typingContainer {
  position: absolute;
  margin: -50px auto;

}
.css-typing p {
  border-right: 0.2em solid $primary;
  font-family: Consolas, monospace;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  color: #999;
}
.css-typing p:nth-child(1) {
  width: 7.3em;
  -webkit-animation: type 3s steps(12, end);
  animation: type 2s steps(12, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid $primary;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid $primary;
  }
  100% {
    border: none;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: tranparent;
  }
}

.typing-demo {
  width: 30ch;
  animation: typing 2s steps(30), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: Consolas, monospace;
  font-size: 19px;
  color: #8bbed7;
  margin: 250px auto;
  padding: 20px;
 
  // position: absolute;
  //top: 585px;
  // left: 590px;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes removeTyping {
  from {
    width: 100%;
  }
  90%,
  to {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
   
  }
}

/* Flip card Animation */
.card {
  position: absolute;
  top: 140px;
  left: 150px;
  width: 100%;
  height: 400px;
  margin: -150px;
  float: left;
  perspective: 500px;
  background: #fff;

}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: transform 1s;
  transform-style: preserve-3d;
}

.fileType {
  color: $primary;
  width: 25%;
}

.card:hover .front {
  // transform: rotateY(180deg);
  transition: transform 0.5s;
  background: $primary;
  animation: fadeInAnimation ease 2s;
  position: absolute;
  width: 100%;
  height: 100%;

  .file,.desc, .score,.fileType {
    color: #fff;
  }

}

.front {
  position: absolute;
}

// .back {
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   background: white;
//   line-height: 300px;
//   color: $primary;
//   text-align: center;
//   font-size: 1px;
//   border-radius: 5px;
//   backface-visibility: hidden;
//   background: $primary;
//   transform: rotateY(180deg);
// }


// loading  

.loadingContainer {
  width: 100%;
  margin: 0 auto;
  align-items: center;
  text-align: center;
}

.loading span {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin: .19em;
  background: #007DB6;
  border-radius: .6em;
  animation: loading 1s infinite alternate;

}

.loading h2 {
  color: $primary;
  font-size: 16px;
}

/*
 * Dots Colors
 * Smarter targeting vs nth-of-type?
 */
.loading span:nth-of-type(2) {
  background: #016bb5;
  animation-delay: 0.2s;
}
.loading span:nth-of-type(3) {
  background: #1c91e6;
  animation-delay: 0.4s;
}
.loading span:nth-of-type(4) {
  background: #52b5fc;
  animation-delay: 0.6s;
}
.loading span:nth-of-type(5) {
  background: #82cbff;
  animation-delay: 0.8s;
}
.loading span:nth-of-type(6) {
  background: #82cbff;
  animation-delay: 1.0s;
}
.loading span:nth-of-type(7) {
  background: #b6c743;
  animation-delay: 1.2s;
}

/*
 * Animation keyframes
 * Use transition opacity instead of keyframes?
 */
@keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}